export const TAG_LIST = [
  "Database",
  "System Design",
  "Algorithm",
  "Backend",
  "Go",
  "Networking",
  "OSystem",
  "English",
  "Frontend",
];

export const LANGUAGE_LIST = ["go", "python", "javascript", "typescript"];
